import { Link } from "gatsby";
import React from "react";
import Icon from "../../images/home.svg";
import ArrowForward from "../../images/list.svg";

function BreadCrumb({ path }) {
  return (
    <div className="bg-[#FFE8B8] h-[74px]">
      <div className=" max-w-[1240px] mx-auto flex items-center h-[74px] xl:px-0 lg:px-4 md:px-6 gap-4">
        <Link to="/">
          <img src={Icon} />
        </Link>
        <img src={ArrowForward} />
        <p className="capitalize text-black">{path.replaceAll("/", "")}</p>
      </div>
    </div>
  );
}

export default BreadCrumb;
