import BoxContainer from "../../components/BoxContainer";
import React from "react";
import Text from "../../components/Text";
import textResolver from "../../core/utils/text-resolver";
import Icon from "../../images/list.svg";
import IconPurple from "../../images/list-purple.svg";
import { Link } from "gatsby";

function AllLanguages({ data, background }) {
  const { dataType = 0 } = data;
  return (
    <>
      <BoxContainer background={background}>
        <div className="w-full max-w-[1240px] mx-auto pt-6 pb-10">
          <Text
            as="h1"
            className="0px:text-4xl sm:text-5xl text-blue text-left font-primary font-extrabold pb-5"
          >
            {dataType === 1 ? data.title : textResolver(data, "title")}
          </Text>
          {data?.languagesData && (
            <div className="w-full max-w-5xl mx-auto grid  grid-rows-10 grid-flow-col gap-6 py-12">
              {data?.languagesData?.map((language, i) => (
                <div key={i} className="flex items-center gap-4">
                  <img src={Icon} />
                  <Link to={language.link} className="text-[#641CE1] text-base">
                    {dataType === 1
                      ? language.title
                      : textResolver(language, "title")}
                  </Link>
                </div>
              ))}
            </div>
          )}
          {data?.documentsData && (
            <div className="w-full max-w-5xl mx-auto grid  grid-rows-10 grid-flow-col gap-6 py-12">
              {data?.documentsData?.map((document, i) => (
                <div key={i} className="flex items-center gap-4">
                  <img src={IconPurple} />
                  <Link to={document.link} className="text-[#641CE1] text-base">
                    {dataType === 1
                      ? document.title
                      : textResolver(document, "title")}
                  </Link>
                </div>
              ))}
            </div>
          )}
        </div>
      </BoxContainer>
    </>
  );
}

export default AllLanguages;
